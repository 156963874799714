<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue'
import { HelperService } from '../../utils/helper.service'
import FormLabel from './label.vue'

export default {
    name: 'TextareaField',
    components: { FormLabel },
    extends: formFieldsInit,
    props: {
        keepSpacesAndLineBreaks: { type: Boolean, required: false, default: true },
    },
    emits: ['updateData'],
    methods: {
        addBrAndNbsp(value: string): string {
            if (!this.keepSpacesAndLineBreaks) {
                return value
            }
            return (new HelperService()).addBrAndNbsp(value)
        },
    },
}
</script>

<template>
    <FormLabel v-if="data && (value !== undefined || rule)">
        <v-textarea
            v-if="rule"
            :id="dataKey"
            class="custom-input"
            :type="type || 'text'"
            density="compact"
            :value="value"
            variant="outlined"
            :suffix="suffix"
            rows="3"
            :disabled="disabled"
            :error-messages="getErrors()"
            @input="validation && validation.$touch()"
            @blur="validation && validation.$touch()"

            @update:model-value="$emit('updateData', $event)"
        />

        <div v-else>
            <div v-if="value" v-html="addBrAndNbsp(value)" />
            <div v-else>
                <small class="font-italic text-disabled">-</small>
            </div>
        </div>
    </FormLabel>
</template>
